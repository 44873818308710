.login__text {
  font-size: 14px;
  line-height: 1.21;
  margin-top: 16px;
  margin-bottom: 16px;
}

@media screen and (max-width: 915px){
  .login__text {
    margin-bottom: 0;
    margin-top: 12px;
    font-size: 12px;
  }
}
