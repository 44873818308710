.label__placeholder {
  font-size: 14px;
  line-height: 1.2;
  color: #C4C4C4;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) scale(1);
  transition: 0.3s;
  pointer-events: none;
}

.label__input:focus + .label__placeholder, .label__placeholder_is-fixed {
  font-size: 12px;
  transform: translateY(-200%) scale(1);
}


@media screen and (max-width: 915px){
  .label__placeholder {
    font-size: 12px;
    line-height: 1.25;
  }
  .label__input:focus + .label__placeholder, .label__placeholder_is-fixed {
    font-size: 10px;
  }
}
