@import url(./__avatar-box/profile__avatar-box.css);
@import url(./__avatar/profile__avatar.css);
@import url(./__add-btn/profile__add-btn.css);
@import url(./__edit-btn/profile__edit-btn.css);
@import url(./__avatar-btn/profile__avatar-btn.css);
@import url(./__heading/profile__heading.css);
@import url(./__name/profile__name.css);
@import url(./__desc/profile__desc.css);
@import url(./__title/profile__title.css);
@import url(./__loading/profile__loading.css);

.profile {
  padding-top: 40px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 915px) {
  .profile {
    flex-direction: column;
    padding: 40px 20px 36px;
  }
}
