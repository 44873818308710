.header__link {
  font-size: 16px;
  line-height: 1.2;
}

@media screen and (max-width: 915px){
  .header__link  {
    font-size: 14px;
  }
}
