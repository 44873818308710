.login__title {
  font-weight: 900;
  font-size: 24px;
  line-height: 1.2;
  margin-top: 0px;
  margin-bottom: 38px;
}

@media screen and (max-width: 915px){
  .login__title {
    font-size: 20px;
    margin-bottom: 28px;
  }
}
