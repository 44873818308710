.place__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
