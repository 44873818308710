@font-face {
  src: url(../../vendor/fonts/Inter-Regular.woff2),
       url(../../vendor/fonts/Inter-Regular.woff);
  font-family: 'Inter';
  font-weight: normal;
}

@font-face {
  src: url(../../vendor/fonts/Inter-Medium.woff2),
       url(../../vendor/fonts/Inter-Medium.woff);
  font-family: 'Inter';
  font-weight: 500;
}

@font-face {
  src: url(../../vendor/fonts/Inter-Black.woff2),
       url(../../vendor/fonts/Inter-Black.woff);
  font-family: 'Inter';
  font-weight: 900;
}
