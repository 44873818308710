.header__logo {
  width: 142px;
  height: 33px;
}

@media screen and (max-width: 915px){
  .header__logo {
    width: 104px;
    height: 25px;
    margin-left: 30px;
    margin-top: 28px;
    grid-area: logo;
  }
}
