.places__list {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 282px);
  justify-content: center;
  column-gap: 18px;
  row-gap: 20px;
  color: black;
}
