@import url(./__logo/header__logo.css);
@import url(./__text-container/header__text-container.css);
@import url(./__name/header__name.css);
@import url(./__menu-button/header__menu-button.css);
@import url(./__link/header__link.css);
@import url(./__link/_type/_login/header__link_type_login.css);
@import url(./__link/_type/_logout/header__link_type_logout.css);

.header {
  padding-top: 44px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgb(84, 84, 84, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 915px) {
  .header {
    padding-top: 0;
    padding-bottom: 32px;
    display: grid;
    grid-template-rows: repeat(2, minmax(min-content, max-content));
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "menu menu"
      "logo button";
    align-items: center;
  }
  .header__info {
    grid-area: button;
    justify-self: end;
    margin-right: 50px;
    margin-top: 34px;
  }
}
