.place__img-wrapper {
  height: 282px;
}

.place__img-wrapper::after {
  width: 100%;
  height: 60px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 7.5e-05) 100%);
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  z-index: 1;
  border-radius: 5px;
  cursor: pointer;
}
