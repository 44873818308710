.lang {
  width: 45px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

.lang__trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.2;
  cursor: pointer;
}

.lang__options {
  position: absolute;
  top: 100%;
  right: 5px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  width: 45px;
}

.lang.open .lang__options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  margin-top: 10px;
}

.lang__option {
  position: relative;
  display: block;
  font-size: 14px;
  cursor: pointer;
  transition: .3s;
  background: #000;
  padding: 5px;
  width: 55px;
}

.lang__option_default {
  display: none;
}

.lang__option:hover {
  cursor: pointer;
  opacity: .7;
}

.lang__option.selected {
  color: #000;
  background-color: #E6E6E6;
}

.lang__arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #9e9e9e;
  transition: .3s;
}

.open .lang__arrow {
  transform: rotate(180deg);
}

.open .lang__arrow::after {
  transform: rotate(-180deg);
}

@media screen and (max-width: 915px) {
  .lang__trigger {
    font-size: 14px;
  }
  .lang__option {
    font-size: 14px;
  }
}
