@import url(./__fields/login__fields.css);
@import url(./__title/login__title.css);
@import url(./__text/login__text.css);

.login {
  max-width: 358px;
  margin: 0 auto;
  padding-top: 60px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 915px){
  .login {
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
