.login__fields {
  border: 0;
  padding: 0;
  margin: 0 0 212px;
}

@media screen and (max-width: 915px){
  .login__fields {
    margin-bottom: 0px;
    /* flex-grow: 1; */
  }
}
