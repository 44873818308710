.profile__avatar-btn {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0;
  border-radius: 50%;
  background: url(../../../images/profile-avatar-btn.svg);
  z-index: 10;
  opacity: 0;
}

.profile__avatar-btn:hover {
  opacity: 1;
}


