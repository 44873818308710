@import url(./__copyright/footer__copyright.css);

.footer {
  padding-top: 66px;
  padding-bottom: 60px;
}

@media screen and (max-width: 915px){
  .footer {
    padding-top: 48px;
    padding-bottom: 36px;
  }
}
