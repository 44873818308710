.profile__loading {
  font-weight: 900;
  font-size: 64px;
  line-height: 100px;
  margin: auto;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (max-width: 915px) {
  .profile__loading {
    font-size: 32px;
  }
}
