.profile__add-btn {
  background: url(../../../images/profile-add-btn.svg), black;
  background-size: 22px 22px;
  min-width: 150px;
  height: 50px;
  border: 2px solid #FFFFFF;
  border-radius: 2px;
}

.profile__add-btn:hover {
  background: url(../../../images/profile-add-btn-hover.svg), black;
}

@media screen and (max-width: 915px){
  .profile__add-btn {
    max-width: 282px;
    width: 100%;
    background-size: 18px 18px;
  }
}
