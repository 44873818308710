.label__input {
  width: 100%;
  padding: 12px 0;
  border: 0;
  border-bottom: 1px solid rgb(0, 0, 0, 0.2);
  font-size: 14px;
  line-height: 1.2;
  outline: none;
  transition: 0.3s;
}

@media screen and (max-width: 915px){
  .label__input {
    font-size: 12px;
    line-height: 1.25;
  }
}
