.header__text-container {
  font-size: 18px;
  line-height: 1.22;
}

@media screen and (max-width: 915px){
  .header__text-container {
    height: 62px;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: menu;
    border-bottom: 1px solid rgb(84, 84, 84, 0.7);
    transition: 0.3s;
  }
}
