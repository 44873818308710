.form__error {
  width: 100%;
  font-size: 14px;
  line-height: 1.25;
  color: #ff0000;
  transition: 0.3s;
  position: absolute;
  top: 50%;
  left: 0;
  opacity: 0;
}
