@import url(./__like/place__like.css);
@import url(./__like-count/place__like-count.css);
@import url(./__like-btn/place__like-btn.css);
@import url(./__like-btn/_active/place__like-btn_active.css);
@import url(./__header/place__header.css);
@import url(./__image/place__image.css);
@import url(./__title/place__title.css);
@import url(./__delete-btn/place__delete-btn.css);
@import url(./__img-wrapper/place__img-wrapper.css);

.place {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}


