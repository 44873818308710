.save-btn {
  min-height: 50px;
  background-color: black;
  color: white;
  font-size: 18px;
  line-height: 1.2;
  border: 0;
  border-radius: 2px;
}

.save-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 915px){
  .save-btn {
    min-height: 40px;
    font-size: 14px;
  }
}
