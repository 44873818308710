.profile__name {
  font-weight: 500;
  font-size: 42px;
  line-height: 56px;
  margin-top: 0;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (max-width: 915px) {
  .profile__name {
    font-size: 27px;
    margin-bottom: 0px;
  }
}
