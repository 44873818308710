.modal__container {
  margin: auto;
  max-width: 430px;
  width: 100%;
  background-color: white;
  color: black;
  padding: 36px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .modal__container {
    width: 75%;
    padding: 32px 20px;
  }
}
