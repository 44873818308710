.header__name {
  font-weight: 500;
  margin-right: 24px;
}

@media screen and (max-width: 915px){
  .header__name {
    font-size: 16px;
    margin-right: 0;
    margin-bottom: 18px;
  }
}
